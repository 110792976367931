import { gql } from '@apollo/client';

export const SEARCH_PEOPLE = gql`
  query getPeopleBySearchQuery($input: GetPeopleBySearchQueryInput!) {
    getPeopleBySearchQuery(input: $input) {
      _id
      affiliate
      crmId
      sfCrmId
      displayName
      email
      thumbnail
      title
      externalUserId
      contractType
    }
  }
`;

export const GET_PEOPLE = gql`
  query masterDataPeople($query: MasterDataPersonQueryInput, $limit: Int = 0, $sortBy: MasterDataPersonSortByInput) {
    masterDataPeople(query: $query, limit: $limit, sortBy: $sortBy) {
      _id
      affiliate
      crmId
      displayName
      email
      jobRole
      thumbnail
      title
      externalUserId
      contractType
    }
  }
`;

export const GET_PERSON = gql`
  query masterDataPerson($query: MasterDataPersonQueryInput!) {
    masterDataPerson(query: $query) {
      _id
      affiliate
      crmId
      displayName
      email
      thumbnail
      title
      externalUserId
      contractType
    }
  }
`;

export const GET_REALM_USERS = gql`
  query findRealmUsersQuery($input: FindRealmUsersQueryResolverInput!) {
    result: findRealmUsersQuery(input: $input) {
      data {
        _id
        name
        externalUserId
        roles
        createdAt
        lastLoginAt
      }
      pagination {
        currentPage
        itemsPerPage
        totalPages
        totalItems
      }
    }
  }
`;

export const UPDATE_REALM_USER = gql`
  mutation updateOneRealmUser($query: RealmUserQueryInput!, $set: RealmUserUpdateInput!) {
    result: updateOneRealmUser(query: $query, set: $set) {
      _id
      name
      externalUserId
      role
      email
      createdAt
      lastLoginAt
    }
  }
`;
