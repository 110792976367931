import { createContext, useEffect, useState } from 'react';
import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { User } from 'realm-web';
import { Locale } from 'date-fns';
import { LicenseInfo } from '@mui/x-license';
import AppThemeProvider from './AppThemeProvider';
import AppRealmProvider from './AppRealmProvider';
import AppAuthProvider from './AppAuthProvider';
import Layout from '../template/components/Layout/Layout';
import AppRouterProvider from './AppRouterProvider';
import getDefaultDateFnsLocale from '../config/date/getDefaultDateFnsLocale';
import getDateFnsLocaleFromString from '../config/date/getDateFnsLocaleFromString';
import { UserTheme } from '../features/users/enums/UserTheme';
import AppSentryProvider from './AppSentryProvider';
import AppMaintenanceProvider from './AppMaintenanceProvider';
import RealmUser from '../models/RealmUser';
import AppConsole from './AppConsole';
import { UserCrmChoice } from '../features/users/enums/UserCrmChoice';

interface AppContextInterface {
  realmUser: RealmUser | null;
  setRealmUser: (realmUser: RealmUser | null) => void;
  realmObject: User | null;
  setRealmObject: (realmObject: User | null) => void;
  apolloClient: ApolloClient<NormalizedCacheObject> | null;
  setApolloClient: (apolloClient: ApolloClient<NormalizedCacheObject> | null) => void;
  regionalFormat: Locale;
  setRegionalFormat: (regionalFormatStr: string) => void;
  darkMode: boolean;
  setDarkMode: (enabled: boolean) => void;
  // TODO : SF - Delete the crm choice
  crmChoice: UserCrmChoice;
  setCrmChoice: (crmChoice: UserCrmChoice) => void;
}

const defaultDateLocale = getDefaultDateFnsLocale();

LicenseInfo.setLicenseKey(import.meta.env.VITE_MUI_LICENSE_KEY as string);

export const AppContext = createContext<AppContextInterface>({
  realmUser: null,
  setRealmUser: () => {},
  realmObject: null,
  setRealmObject: () => {},
  apolloClient: null,
  setApolloClient: () => {},
  regionalFormat: defaultDateLocale,
  setRegionalFormat: () => {},
  darkMode: false,
  setDarkMode: () => {},
  // TODO : SF - Delete the crm choice
  crmChoice: UserCrmChoice.SUGAR,
  setCrmChoice: () => {},
});

function App() {
  const [realmUser, setRealmUser] = useState<RealmUser | null>(null);
  const [realmObject, setRealmObject] = useState<User | null>(null);
  const [apolloClient, setApolloClient] = useState<ApolloClient<NormalizedCacheObject> | null>(null);
  const [regionalFormat, setRegionalFormat] = useState<Locale>(defaultDateLocale);
  const [darkMode, setDarkMode] = useState(localStorage.getItem('theme') === UserTheme.Dark);
  // TODO : SF - Delete the crm choice
  const [crmChoice, setCrmChoice] = useState(UserCrmChoice.SUGAR);

  const setRegionalFormatFromStr = (regionalFormatStr: string) => {
    setRegionalFormat(getDateFnsLocaleFromString(regionalFormatStr));
  };

  /**
   * On mount, persist in session storage the redirection URI. It will be used by AppRouterProvider.tsx once the user
   * will be authenticated by the IDP. The aim will be to redirect the user to the page he wanted to visit initially
   * (before the IDP callback).
   */
  useEffect(() => {
    if (!sessionStorage.getItem('redirectURI')) {
      sessionStorage.setItem('redirectURI', window.location.href);
    }
  }, []);

  useEffect(() => {
    if (realmUser?.regionalFormat) {
      setRegionalFormatFromStr(realmUser?.regionalFormat as string);
    }
  }, [realmUser?.regionalFormat]);

  useEffect(() => {
    if (realmUser?.crmChoice) {
      setCrmChoice(realmUser?.crmChoice as UserCrmChoice);
    }
  }, [realmUser?.crmChoice]);

  useEffect(() => {
    if (realmUser?.theme) {
      setDarkMode(realmUser?.theme === UserTheme.Dark);

      // The selected theme is also stored in Local Storage
      // It's important to improved UX (avoid "flash"  between light -> dark)
      localStorage.setItem('theme', realmUser?.theme === UserTheme.Dark ? UserTheme.Dark : UserTheme.Light);
    }
  }, [realmUser?.theme]);

  return (
    <AppContext.Provider
      value={{
        realmUser,
        setRealmUser,
        realmObject,
        setRealmObject,
        apolloClient,
        setApolloClient,
        regionalFormat,
        setRegionalFormat: setRegionalFormatFromStr,
        darkMode,
        setDarkMode,
        // TODO : SF - Delete the crm choice
        crmChoice,
        setCrmChoice,
      }}
    >
      <AppThemeProvider>
        <AppAuthProvider>
          <AppSentryProvider>
            <AppMaintenanceProvider>
              <AppRealmProvider>
                <AppRouterProvider>
                  <Layout />
                </AppRouterProvider>
              </AppRealmProvider>
            </AppMaintenanceProvider>
          </AppSentryProvider>
        </AppAuthProvider>
      </AppThemeProvider>
      <AppConsole />
    </AppContext.Provider>
  );
}

export default App;
