import { Language } from '../features/languages/enums/language.enum';
import { UserLanguage } from '../features/users/enums/UserLanguage';

export const getLanguageLabel = (key: string | UserLanguage) => {
  switch (key) {
    case Language.Arabic:
      return 'Arabic';
    case Language.Korean:
      return 'Korean';
    case Language.Czech:
      return 'Czech';
    case Language.Portuguese:
      return 'Portuguese';
    case Language.German:
      return 'German';
    case Language.English:
      return 'English';
    case Language.Spanish:
      return 'Spanish';
    case Language.French:
      return 'French';
    case Language.Italian:
      return 'Italian';
    case Language.Russian:
      return 'Russian';
    case Language.Thai:
      return 'Thai';
    case Language.Japanese:
      return 'Japanese';
    case Language.ChineseSimplified:
      return 'Chinese (Simplified)';
    case Language.ChineseTraditional:
      return 'Chinese (Traditional)';
    default:
      return key;
  }
};
